itd5ka#tutorial.container {
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #000;
  justify-content: center;
  display: flex;
  position: absolute;
}

itd5ka#tutorial.container itd5ka#tutorial_header {
  height: 10%;
  background: linear-gradient(0deg, #0000 0%, #000 100%);
  top: 0;
}

itd5ka#tutorial.container itd5ka#tutorial_message {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  flex-direction: column;
  place-content: flex-end center;
  align-items: center;
  margin: auto;
  font-size: 3.6vw;
  display: flex;
}

itd5ka#tutorial.container itd5ka#tutorial_footer {
  height: 10%;
  background: linear-gradient(0deg, #000 20%, #0000 100%);
  padding-top: 1rem;
  bottom: 0;
}

itd5ka#tutorial.container button#tutorial_button_prev {
  opacity: 1;
  filter: none;
  mix-blend-mode: normal;
  background-color: #0000;
  border: none;
  left: 0;
}

itd5ka#tutorial.container button#tutorial_button_next {
  opacity: 1;
  filter: none;
  mix-blend-mode: normal;
  background-color: #0000;
  border: none;
  right: 0;
}

itd5ka#tutorial.container .tutorial_button {
  width: 50%;
  height: 100%;
  position: absolute;
}

itd5ka#tutorial.container .tutorial_fade {
  width: 100%;
  position: absolute;
}

itd5ka#tutorial.container itd5ka#tutorial_page {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
}

/*# sourceMappingURL=index.4a94f3e1.css.map */
