itd5ka#tutorial.container {
	width: 100%;
	height: 100%;
	z-index: 3;
	position: absolute;
	display: flex;
	background: black;
    justify-content: center;

	itd5ka#tutorial_header {
		background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		top: 0;
		height: 10%;
	}

	itd5ka#tutorial_message {
		width: 100%;
		height: 100%;
		color: white;
		font-size: 3.6vw;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-content: flex-end;
		justify-content: center;
		white-space: nowrap;
		margin: auto;
		align-items: center;
	}

	itd5ka#tutorial_footer {
		height: 10%;
		background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0, 0, 0, 0) 100%);
		bottom: 0;
		padding-top: 1rem;
	}

	button#tutorial_button_prev {
		left: 0;
		background-color: #00000000;
		opacity: 1;
		filter: none;
		mix-blend-mode: normal;
		border: none;
	}

	button#tutorial_button_next {
		right: 0;
		background-color: #00000000;
		opacity: 1;
		filter: none;
		mix-blend-mode: normal;
		border: none;
	}

	.tutorial_button {
		width: 50%;
		height: 100%;
		position: absolute;
	}

	.tutorial_fade {
		width: 100%;
		position: absolute;
	}

	itd5ka#tutorial_page {
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		align-self: center;
	}
}
